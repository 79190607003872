import { takeLatest, put, call } from 'redux-saga/effects';
import { SEND_EMAIL_SAGA } from "../types_saga";
import { sendingOnAction, sendingOffAction, sendedTrueAction, resetFormAction } from "../../redux/rootReducer";
import growl from "../../lib/growl/growl";

const request = data => {
    return fetch(`https://alga.by/send_order`, {
        method: 'post',
        body: data
    })
        .then(response => response.json()).catch(error => console.log(error))
};

function* workerSendEmailSaga(args) {
    yield put(sendingOnAction());
    try {
        let formData = new FormData();
        formData.append('name', args.data.name);
        formData.append('phone', args.data.phone);
        formData.append('message', args.data.message);

        const response = yield call (request, formData);

        if (response.success) {
            yield put(resetFormAction());
            yield growl({ success: 1, message: response.message });
            yield put(sendedTrueAction());
        }
        else if (response.error) {
            yield growl({ error: 1, message: response.message });
        }
    }
    catch(error) {
        console.log(error);
    }
    finally {
        yield put(sendingOffAction());
    }
}

export default function* watcherSendEmailSaga() {
    yield takeLatest(SEND_EMAIL_SAGA, workerSendEmailSaga);
}