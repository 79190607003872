import React from 'react';
import './description.css';
import phone1 from './i/phone1.png';
import phone2 from './i/phone2.png';
import phone3 from './i/phone3.png';
import mac from './i/mac.png';
import Form from "../Form/Form";

const Description = () => {
    return (
        <div className="description">
            <div className="description__box description__box--flex">
                <div className="scrollref" id={ 'description' }/>
                <div className="description__content">
                    <h3 className="description__title">Простой и нативно понятный <br className="description__br"/> интерфейс приложения</h3>
                    <p>
                        Пользоваться мобильным приложением <br className="description__br"/>
                        очень просто. Ваши клиенты смогут <br className="description__br"/>
                        самостоятельно списывать тренировки <br className="description__br"/>
                        и будут иметь доступ к информации о <br className="description__br"/>
                        своих абонементах и посещениях.
                    </p>
                    <p>
                        Для списания тренировки <br className="description__br"/>
                        достаточно всего 2-х нажатий!
                    </p>
                </div>
                <div className="description__image description__image--right">
                    <div className="description__phone-wrapper">
                        <img src={ phone1 } alt={ '' } className="description__img"/>
                    </div>
                </div>
            </div>
            <div className="description__box description__box--flex">
                <div className="description__image">
                    <div className="description__phone-wrapper">
                        <img src={ phone2 } alt='' className="description__img"/>
                    </div>
                </div>
                <div className="description__content">
                    <h3 className="description__title">Стильно и современно</h3>
                    <p>
                        Приложение персонализируется под <br className="description__br"/>
                        ваше заведение. Оно подчеркнёт ваш <br className="description__br"/>
                        статус и выделит вас среди конкурентов.
                    </p>
                    <p>
                        Электронный абонемент избавит вас <br className="description__br"/>
                        от дополнительных расходов на приобритение <br className="description__br"/>
                        бумажных или пластиковых аналогов.
                    </p>
                    <p className="bold">С помощью приложения ваши клиенты <br className="description__br"/> смогут производить оплаты онлайн <br className="description__br"/> в удобное для них время, <br className="description__br"/> в удобном для них месте</p>
                    <p>
                        Больше не возникнет вопросов, <br className="description__br"/>
                        сколько тренировок осталось, или до <br className="description__br"/>
                        какого числа действует абонемент. <br className="description__br"/>
                        Вся информация всегда будет под <br className="description__br"/>
                        рукой у ваших посетителей.
                    </p>
                </div>
            </div>
            <div className="description__box description__box--flex">
                <div className="description__content">
                    <h3 className="description__title">Автоматизация записи</h3>
                    <p>
                        Вам только нужно создать занятие — <br className="description__br"/> <span className="bold">всё ослальное сервис сделает за вас!</span>
                    </p>
                    <p>
                        Сервис автоматически проконтролирует количество записавшихся на занятие, при этом есть возможность записи в лист ожидания — вы не потеряете деньги если кто-нибудь откажется от записи.
                    </p>
                    <p>
                        Вы будете вкурсе всех изменений в записях, благодаря системе уведомлений.
                    </p>
                    <p>
                        При необходимости, сервис напомнит вашим клиентам о предстоящем занятии.

                        <br className="description__br"/>
                    </p>
                </div>
                <div className="description__image">
                    <div className="description__phone-wrapper">
                        <img src={ phone3 } alt='' className="description__img"/>
                    </div>
                </div>
            </div>
            <div className="description__box">
                <h3 className="description__title description__title--center">Простой переход на новый сервис</h3>
                <p>Не требует переноса клиентской базы! <br/> Ваши посетители всё сделают за вас, регистрируясь в мобильном приложении.</p>
            </div>
            <div className="description__box">
                <h3 className="description__title description__title--center">Информативная и функциональная панель для администратора</h3>
                <p>Панель администратора позволить иметь полную картину о работе заведения:</p>
                <ol>
                    <li>Отслеживать информацию о заведении в режиме реального времени</li>
                    <li>Получать полную информацию о посетителях</li>
                    <li>Контролировать абонементы, количество посещений и сроки их действий</li>
                    <li>Отображать статистику работы заведения</li>
                    <li>Выполнять рассылки с информацией о ваших акциях, новых предложениях, измениниях <br className="description__br"/>режима работы заведения и т.д.</li>
                    <li>Настраивать стили QR-кода, размещаемого в заведении, для установки приложения и списания тренировок</li>
                </ol>
            </div>
            <div className="description__box">
                <div className="description__panel-wrapper">
                    <img className="description__img" src={ mac } alt=''/>
                </div>
            </div>
            <div className="description__box">
                <div className="description__title description__title--center">Подробное ознакомление с панелью администратора</div>
                <p>
                    Для тестирования панели администратора перейдите по <a href="https://alga.by/fitness" target="_blank">ссылке</a> и войдите как гость. <br className="description__br"/>
                    Вам будет доступен весь функционал, который можно протестировать совместно с <br className="description__br"/>
                    мобильным приложением.
                </p>
            </div>
            <div className="description__box">
                <div className="description__title description__title--center">Быстрый запуск!</div>
                <p>
                    Для запуска сервиса оставьте заявку в форме ниже. <br className="description__br"/>
                    Мы свяжемся с вами в течение рабочего дня.
                </p>
                <p>Срок запуска сервиса не более 2-х недель.</p>
            </div>
            <div className="description__box">
                <div className="scrollref" id={ 'price' }/>
                <div className="description__title description__title--center">Стоимость предоставляемых услуг</div>
                <p>
                    Стоимость настройки сервиса с запуском мобильного приложения <br className="description__br"/>
                    составляет 100 рублей, и оплачивается <span style={{ fontWeight: 600 }}>разово</span>.
                </p>
                <p>Стоимость услуг по автоматизации составляет 50 рублей в месяц за одно заведение.</p>
            </div>
            <div className="description__box">
                <div className="scrollref" id={ 'order' }/>
                <div className="description__title description__title--center">Форма обратной связи</div>
                <Form/>
            </div>
        </div>
    );
};

export default Description;