import React, { useState } from 'react';
import './form.css';
import TextareaAutosize from 'react-textarea-autosize';
import { useDispatch, useSelector } from "react-redux";
import { setMessageAction, setNameAction, setPhoneAction, sendedFalseAction } from "../../redux/rootReducer";
import { sendEmailSaga } from "../../redux/actions_saga";

const Form = () => {
    const dispatch = useDispatch();
    const name = useSelector(state => state.name);
    const phone = useSelector(state => state.phone);
    const message = useSelector(state => state.message);
    const sending = useSelector(state => state.sending);
    const sended = useSelector(state => state.sended);

    const [agree, setAgree] = useState(false);

    return (
        <>
            {
                sended
                ?
                    <div className="form__sended">
                        <div className="form__sended-text">Форма отправлена!</div>
                        <div className="form__sended-retry" onClick={ () => dispatch(sendedFalseAction()) }>Написать ещё</div>
                    </div>
                :
                    <div className="form">
                        <div className="input__wrapper input__wrapper--inline">
                            <div className="input__caption">Имя</div>
                            <input type="text" value={ name } className="input" onInput={ event => {
                                dispatch(setNameAction(event.target.value));
                            } }/>
                        </div>
                        <div className="input__wrapper input__wrapper--inline">
                            <div className="input__caption">Почта или телефон</div>
                            <input type="text" value={ phone } className="input" onInput={ event => {
                                dispatch(setPhoneAction(event.target.value));
                            } } />
                        </div>
                        <div className="input__wrapper">
                            <div
                                className="input__caption"
                                data-full="Вопрос, предложение или заявка на разработку"
                                data-short="Текст сообщения"
                            />
                            <TextareaAutosize
                                className="input input--textarea"
                                value={ message }
                                onInput={ event => dispatch(setMessageAction(event.target.value)) }
                            />
                        </div>
                        <div className="input__wrapper">
                            <div className={ `checkbox ${ agree ? 'checkbox--active' : '' }` }
                                onClick={ () => setAgree(!agree) }
                            >
                                <i className="checkbox__ico"/>
                                <div className="checkbox__caption">
                                    Даю согласие на обработку своих персональных данных, на условиях и для целей, <br /> определённых
                                    <a
                                        target={ '_blank' }
                                        className="link" href="https://alga.by/docs/privacy_policy_site_fitness.pdf"
                                        onClick={ event => event.stopPropagation() }
                                    > Политикой конфиденциальности</a>
                                </div>
                            </div>
                        </div>
                        <div
                            className={ `form__submit btload btload--white ${ sending ? 'btload--loading' : '' } ${ agree ? '' : 'form__submit--inactive' }`}
                            onClick={ () => {
                                dispatch(sendEmailSaga({ name, phone, message }));
                            } }
                        >
                            {
                                sending ? false : 'Отправить'
                            }
                            <i className="btload__ico" />
                            <i className="btload__ico" />
                            <i className="btload__ico" />
                        </div>
                    </div>

            }
        </>
    );
};

export default Form;