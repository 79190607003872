import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <div className="footer">
            <div className="scrollref" id={ 'contacts' }/>
            <div className="footer__white">
                <div className="footer__content">
                    <div className="footer__logo"/>
                    <div className="footer__contacts">
                        <a href="tel:+375298870201" className="footer__contact footer__contact--phone">+375 (29) 887-02-01</a>
                        <a href="mailto:info@alga.by" className="footer__contact footer__contact--mail">info@alga.by</a>
                    </div>
                </div>
                <div className="footer__content">
                    <div className="footer__info">
                        <div className="footer__links">
                            <a
                                href="https://alga.by/docs/offer_fitness.pdf"
                                target={ '_black' }
                                className="footer__link"
                            >Оферта и договор</a>
                            <a
                                href="https://alga.by/docs/privacy_policy_site_fitness.pdf"
                                target={ '_black' }
                                className="footer__link"
                            >Политика корфиденциальности</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer__color">

            </div>
        </div>
    );
};

export default Footer;