import React from "react";
import './app.css';
import Header from "./components/Header/Header";
import Description from "./components/Description/Description";
import Footer from "./components/Footer/Footer";

const App = () => {
  return (
    <>
        <Header/>
        <Description/>
        <Footer/>
    </>
  );
};

export default App;
