import { configureStore } from '@reduxjs/toolkit'
import { reducer } from './rootReducer'
import createSagaMiddleware from 'redux-saga';

import watcherSendEmailSaga from "./sagas/sendEmail";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: reducer,
    middleware: [sagaMiddleware]
});

export default store;

sagaMiddleware.run(watcherSendEmailSaga);