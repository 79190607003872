import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";

const rootReducer = createSlice({
    name: 'reducer',
    initialState,
    reducers: {
        setNameAction: (state, action) => { state.name = action.payload },
        setPhoneAction: (state, action) => { state.phone = action.payload },
        setMessageAction: (state, action) => { state.message = action.payload },
        resetFormAction: state => { state.name = ''; state.phone = ''; state.message = ''; },
        sendingOnAction: state => { state.sending = true },
        sendingOffAction: state => { state.sending = false },
        sendedTrueAction: state => { state.sended = true },
        sendedFalseAction: state => { state.sended = false }
    }
});

export const reducer = rootReducer.reducer;
export const {
    setNameAction, setPhoneAction, setMessageAction, resetFormAction, sendingOnAction, sendingOffAction,
    sendedTrueAction, sendedFalseAction
} = rootReducer.actions;