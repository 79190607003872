import React, { useEffect, useRef, useState } from 'react';
import './header.css';

const Header = () => {

    const [stickyHeader, setStickyHeader] = useState(false);

    const callback = function(entries) {
        entries.forEach(entry => {
            // Если на экране есть хедер, убираем липкий хедер
            if (entry.isIntersecting) {
                setStickyHeader(false);
            }
            else {
                setStickyHeader(true);
            }
        });
    };

    const headerRef = useRef(null);

    useEffect(() => {
        let observer = new IntersectionObserver(callback);

        observer.observe(headerRef.current);

        return () => {
            observer.unobserve(headerRef.current);
        };
    }, []);

    return (
        <div className="header">
            <div className={ `header__sticky ${ stickyHeader ? 'header__sticky--visible' : '' }` }>
                <div className="header__sticky-content">
                    <div className="header__logo" />
                    <div className="header__navs">
                        <a href="#description" className="header__nav">Описание</a>
                        <a href="#price" className="header__nav">Цены</a>
                        <a href="#order" className="header__nav">Заказать</a>
                        <a href="#contacts" className="header__nav">Контакты</a>
                    </div>
                </div>
            </div>
            <div className="header__white">
                <div className="header__content">
                    <div className="header__top" ref={ headerRef } >
                        <div className="header__logo" />
                        <div className="header__navs">
                            <a href="#description" className="header__nav">Описание</a>
                            <a href="#price" className="header__nav">Цены</a>
                            <a href="#order" className="header__nav">Заказать</a>
                            <a href="#contacts" className="header__nav">Контакты</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header__color">
                <div className="header__content">
                    <div className="header__title-wrapper">
                        <h1 className="header__title">Цифровое решение автоматизации <br className="header__br"/> вашего тренажёрного зала или<br className="header__br"/> фитнес-клуба «Alga Fitness»</h1>
                        {/*<a href={ 'https://alga.by/how_qr_work' } target={ '_blank' } className="header__how-work">Как это работает?</a>*/}
                    </div>
                    <div className="header__sub-title">Включает в себя: <br className="header__br"/> индивидуализированное мобильное приложение, <br className="header__br"/> мощную панель управления и<br className="header__br"/> систему электронных абонементов </div>
                    <h3 className="header__sub-title" style={{ fontSize: '18px', lineHeight: '21px' }}>Посетители сами списывают тренировки, сканируя QR-код, <br className="header__br"/> размещённый в заведении. <br className="header__br"/> Протестируйте это в мобильном приложении! </h3>
                    <div className="header__apps">
                        <a href='https://apps.apple.com/ru/app/alga-fitness/id1591166506' target='_blank' className="header__app header__app--app-store"/>
                        <a href='https://play.google.com/store/apps/details?id=by.alga.fitness' target='_blank' className="header__app header__app--google-play"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;