import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import App from './App';
import store from "./redux/store";

import './reset.css';
import './assets/styles/btload.css';
import './lib/growl/growl.css';

const container = document.querySelector('.layout');
const root = createRoot(container);

root.render(
<Provider store={ store } >
    <React.StrictMode>
        <App />
    </React.StrictMode>
</Provider>
);