export default function growl(data) {

  let type,
      message;

  if (data.success) {
    type = 'success';
  }
  else if (data.error) {
    type = 'error';
  }

  message = data.message;

  var parent = document.querySelector('.growl');

  if (!parent) {
    parent = document.createElement('div');
    parent.className = 'growl';
    document.body.appendChild(parent);
  }


  var msg;

  msg = document.createElement('div');
  msg.className = 'growl__item growl__item--' + type;

  msg.innerHTML = [
    '<i class="growl__ico"></i>',
    '<div class="growl__text">' + message + '</div>'
  ].join('');



  if (parent.querySelector('.growl__item')) {
    parent.insertBefore(msg, parent.querySelector('.growl__item'));
  } else {
    parent.appendChild(msg);
  }



  var close = function(element) {

    setTimeout(function() {

      if (element.firstElementChild) {
        element.lastElementChild.classList.add('growl__item--collapsed');
      }


      setTimeout(function() {
        if (element.firstElementChild) {
          element.lastElementChild.remove();
        } else {
          element.remove();
        }

      }, 450);

    }, 5000);

  };


  if (parent.children.length + 1 > 4) {

    close(parent, 0);

  }



  // msg.offsetTop;
  setTimeout( () => {
    msg.classList.add('growl__item--shown');
  }, 50);

  close(parent, 5000);

}